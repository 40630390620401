<template>
  <keep-alive>
    <div>
      <b-card>
        <b-row class="pt-1 justify-content-between">
          <b-col cols="12" md="6">
            <b-row>
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  id="btn-radios-2"
                  v-model="statusFilter"
                  :options="statusOptions"
                  label="text"
                  :aria-describedby="ariaDescribedby"
                  button-variant="outline-primary"
                  size="md"
                  name="radio-btn-outline"
                  buttons
                ></b-form-radio-group>
              </b-form-group>
              <b-col>
                <b-button variant="outline-secondary" @click="exportAllMatters">
                  Export CSV
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" md="6">
            <b-row class="justify-content-end">
              <b-col cols="6">
                <b-input-group class="mb-1">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="searchQuery"
                    placeholder="Find Your Matters..."
                    autofocus
                    size="md"
                    autocomplete="off"
                    class="no-outline"
                    type="search"
                    debounce="500"
                  />
                </b-input-group>
              </b-col>
              <b-col cols="6">
                <!-- <label for="basic-password">Select Type</label> -->
                <v-select
                  class="invoice-filter-select cursor-pointer"
                  placeholder="Select Court"
                  v-model="courtFilter"
                  :options="userCourts"
                  label="title"
                >
                  <template slot="selected-option" slot-scope="option">
                    <span></span>
                    {{ option.title }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.title }} - ({{ option.count }})
                  </template>
                </v-select>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
      <b-card no-body>
        <b-overlay
          :show="$store.state.matterStore.mattersLoading"
          rounded="sm"
          variant="transparent"
          opacity="0.5"
          blur="2px"
        >
          <b-table
            ref="refInvoiceListTable"
            :items="fetchInvoices"
            responsive
            :fields="tableColumns"
            primary-key="_id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="No matching records found"
            :sort-desc.sync="isSortDirDesc"
            class="position-relative"
          >
            <template #head(invoiceStatus)>
              <feather-icon icon="TrendingUpIcon" class="mx-auto" />
            </template>

            <template #cell(title)="data">
              <router-link
                class="mb-1 text-primary"
                :to="{
                  name:
                    Object.keys(data.item).includes('hc_name') &&
                    data.item.hc_name.includes('oc-')
                      ? 'dashboard-other-matter-preview'
                      : 'dashboard-matter-preview',
                  params: { id: data.item._id }
                }"
                target="_blank"
              >
              <p class="text-secondary"
                    v-if="Object.keys(data.item).includes('file_no')"
                    >File No.: {{ data.item.file_no }}</p
                  >
                <h6 class="text-primary">
                  {{ data.item.title }}
                </h6>
                <div v-if="Object.keys(data.item).includes('hearing_dates')">
                  <small
                    class="m-0 text-success font-weight-bold"
                    v-if="data.item.hearing_dates !== null"
                  >
                    {{ getNextDate({ dates: data.item.hearing_dates }) }}
                  </small>
                  <small class="m-0" v-else>--</small>
                </div>
                <small class="m-0" v-else>--</small>
              </router-link>
            </template>

            <!-- Column: Unique Id -->
            <template #cell(unique_id)="data">
              <b-col>
                <h6
                  class="text-nowrap font-weight-bold"
                  v-if="data.item.unique_id"
                >
                  {{ getUniqueId(data.item) }}
                </h6>
                <div v-if="Object.keys(data.item).includes('ctitle')">
                  <small class="text-secondary font-italic"
                    >{{ data.item.ctitle }}
                    {{
                      Object.keys(data.item).includes('other_court_name') &&
                      data.item.other_court_name !== null
                        ? '- ' + data.item.other_court_name 
                        : ''
                    }}</small
                  >
                </div>
              </b-col>
            </template>

            <!-- Column: Balance -->
            <template #cell(case_status)="data">
              <template
                v-if="data.item.case_status.toLowerCase() === 'pending'"
              >
                <b-badge pill variant="light-primary">
                  {{ data.item.case_status }}
                </b-badge>
              </template>
              <template v-else>
                <b-badge pill variant="light-danger">
                  {{ data.item.case_status }}
                </b-badge>
              </template>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <router-link
                  class="mb-1 text-primary"
                  :to="{
                    name:
                      Object.keys(data.item).includes('hc_name') &&
                      data.item.hc_name.includes('oc-')
                        ? 'dashboard-other-matter-preview'
                        : 'dashboard-matter-preview',
                    params: { id: data.item._id }
                  }"
                  target="_blank"
                  ><b-badge variant="primary" pill class="badge-round mr-1">
                    <feather-icon
                      :id="`matter-${data.item._id}-preview-icon`"
                      icon="EyeIcon"
                      size="16"
                      class="mr-25"
                    />
                    <span>View</span>
                  </b-badge></router-link
                >
              </div>
            </template>
          </b-table>
        </b-overlay>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalInvoices"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </keep-alive>
  <!-- Table Container Card -->
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BOverlay,
  VBTooltip,
  BInputGroup,
  BFormGroup,
  BFormRadioGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormRadio
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { formatDate } from '@core/utils/filter'
import moment from 'moment'

// import store from '@/store'
import useMatterList from './useMatterList'
import defaultConfig from '@/configs/defaultConfig'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormRadioGroup,
    BTooltip,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    vSelect,
    BFormRadio
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {}
  },
  beforeMount() {},
  methods: {
    getNextDate({ dates }) {
      var date = '--'
      var dd = []
      const filteredDates = dates.filter(
        (date) => moment(date) >= moment().startOf('day')
      )
      for (let i = 0; i < filteredDates.length; i++) {
        dd.push(moment(filteredDates[i]).format('Do, MMMM YYYY'))
      }
      date = dd.join(' || ')
      return date
    },
    // delete alert
    deleteAlert(_id) {
      this.$swal({
        title: 'Warning',
        icon: 'warning',
        text: 'You really want to delete this matter',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('matterStore/deleteMatter', {
              ctx: this,
              _id: _id
            })
            .then((result) => {
              this.refetchData()
            })
        }
      })
    },
    exportAllMatters() {
      // const url =
      //   defaultConfig.baseUrl +
      //   defaultConfig.exportAllMattersEndPoint +
      //   '&token=' +
      //   localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)
      // window.open(url, '_blank')
      this.$store.dispatch('matterStore/exportAllMatter', {
        ctx: this
      })
    }
  },

  setup(props) {
    const statusOptions = ['Pending', 'Closed', 'All']
    let userCourts = JSON.parse(localStorage.getItem('usercourts'))
    console.log(userCourts, 'user Court')
    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,

      statusFilter,
      courtFilter,

      refetchData
    } = useMatterList()

    return {
      userCourts,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      courtFilter,

      statusFilter,
      statusOptions,
      refetchData,
      formatDate,

      avatarText
    }
  }
}
</script>
