<template>
  <div class="mb-3">
    <matter-list />
    <!-- <b-tabs pills align="right">
      <b-tab title="Active" active lazy>
        <matter-list :type="'pending'" />
      </b-tab>
      <b-tab activ title="Closed" lazy>
        <matter-list :type="'closed'" />
      </b-tab>
      <b-tab title="All Matters" lazy>
        <matter-list :type="'all'" />
      </b-tab>
      <template #tabs-end>
        <b-row class="ml-50">
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchQuery"
              placeholder="Find Your Matters..."
              autofocus
              size="lg"
              autocomplete="off"
              class="no-outline"
              type="search"
              debounce="500"
            />
          </b-input-group>
        </b-row>
      </template>
    </b-tabs> -->
  </div>
</template>

<script>
import MatterList from './matter-list/MatterList.vue'
import {
  BTabs,
  BTab,
  BButton,
  BRow,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormInput
} from 'bootstrap-vue'

export default {
  components: {
    MatterList,
    BTabs,
    BTab,
    BButton,
    BRow,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput
  }
}
</script>
